<template lang="pug">
  div(class="language-cell")
    img(v-if="isNl" src="@/assets/img/flags/nl.svg" alt="NL" width="16" height="16")
    img(v-else-if="isEn" src="@/assets/img/flags/en.svg" alt="EN" width="16" height="16")
    span(v-else) {{ lang }}
</template>

<script>
export default {
  props: {
    lang: String,
  },
  computed: {
    isEn() {
      return this.lang === 'en';
    },
    isNl() {
      return this.lang === 'nl';
    },
  }
};
</script>

<style lang="scss" scoped>
.language-cell {
  text-align: center;
}
</style>